import { createStore, withProps, select } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

import type { Auth } from './types';

export const INITIAL_AUTH_PROPS = {
  access_token: {
    payload: '',
  },
  refresh_token: {
    payload: '',
  },
  investor: null,
  user: null,
};
const authStore = createStore({ name: 'auth' }, withProps<Auth>(INITIAL_AUTH_PROPS));

export const persist = persistState(authStore, {
  key: '@authInvestor',
  storage: localStorageStrategy,
});

export class AuthRepository {
  authStore$ = authStore.pipe(select((state) => state));

  updateAuth(props: Auth) {
    authStore.update((state) => ({
      ...state,
      ...props,
    }));
  }
}
