import { createStore, select, withProps } from '@ngneat/elf';

import { SupportedLocales } from './types';

interface AppProps {
  locale: SupportedLocales;
}

const appStore = createStore({ name: 'app' }, withProps<AppProps>({ locale: 'pt-BR' }));

export class AppRepository {
  appStore$ = appStore.pipe(select((state) => state));

  updateApp(props: AppProps) {
    appStore.update((state) => ({
      ...state,
      ...props,
    }));
  }
}
