import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import dayjs from 'dayjs';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import enUS from 'antd/lib/locale/en_US';

import { SupportedLocales } from './store/app/types';
import { AppRepository } from './store/app/repository';

import AppRoutes from './routes';

import 'antd/dist/antd.css';

const App = () => {
  const [currentLocale, setCurrentLocale] = useState<SupportedLocales>('pt-BR');

  const updateLocale = useCallback((pLocale) => {
    setCurrentLocale(pLocale);
  }, []);

  useEffect(() => {
    new AppRepository().appStore$.subscribe(({ locale }) => {
      updateLocale(locale);
      i18n.changeLanguage(locale);
      dayjs.locale(locale);
    });
  }, []);

  return (
    <ConfigProvider locale={currentLocale === 'pt-BR' ? ptBR : enUS}>
      <AppRoutes />
    </ConfigProvider>
  );
};

export default App;
