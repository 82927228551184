import React from 'react';

import { Spin } from 'antd';

const LoadingIndicator = () => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
    >
      <Spin size="large" />
    </div>
  );
};

export default LoadingIndicator;
