import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import LoadingIndicator from '../components/LoadingIndicator';
import RequireAuth from '../components/RequireAuth';

const InvestmentsRoute = lazy(() => import('./InvestmentsRoute'));
const PurchasesRoute = lazy(() => import('./PurchasesRoute'));
const Login = lazy(() => import('../pages/Login'));

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />

          <Route
            path="investments/*"
            element={
              <RequireAuth>
                <InvestmentsRoute />
              </RequireAuth>
            }
          />
          <Route
            path="purchases/*"
            element={
              <RequireAuth>
                <PurchasesRoute />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
