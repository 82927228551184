import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthRepository } from '../pages/Login/state/auth.repository';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    new AuthRepository().authStore$.subscribe(({ access_token }) => {
      if (!access_token.payload) {
        navigate('/login', { state: { from: location }, replace: true });

        window.location.reload();
        return;
      }
    });
  }, []);

  return children;
};

export default RequireAuth;
